export const checkIsSafari = () => {
  var ua = navigator.userAgent.toLowerCase();
  if (ua.indexOf("safari") !== -1) {
    return true;
  }
  return false;
};

export const checkIsMobile = () => {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
};

export const checkIsPositiveInteger = (v) => Number.isInteger(v);

export const checkIsPromotionCode = (v) => v.length === 8;

export const checkIsMobileNumbers = (v) => /^[1][3456789][0-9]{9}$/.test(v);

export const checkIsExist = (v) => !!v;

export const checkIsAccount = (v) =>
  /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,16}$/.test(v);

export const checkIsPassword = (v) => v.length >= 6 && v.length <= 16;
