export const getApiDetail = ({ method, apiName }) => {
  const apiDetail = apiDetails[method][apiName];
  if (apiDetail) return JSON.parse(JSON.stringify(apiDetail));
  else throw new Error(`Can't find any apiDetail: ${method}-${apiName}`);
};

const apiDetails = {
  GET: {
    siteconfig: {
      url: "/api/siteconfig",
      method: "GET",
    },
    wallets: {
      url: "/api/wallets",
      method: "GET",
    },
    navigations: {
      url: "/api/navigations",
      method: "GET",
    },
    announcements: {
      url: "/api/announcements",
      method: "GET",
    },
    gameList: {
      url: "/games",
      method: "GET",
    },
    tabList: {
      url: "/games/{gameID}/playTypes",
      method: "GET",
      params: {
        gameID: null,
      },
    },
    winInfo: {
      url: "/api/winInfo",
      method: "GET",
    },
  },
  POST: {
    login: {
      url: "/api/login",
      method: "POST",
      data: {
        account: "",
        password: "",
      },
    },
    logout: {
      url: "/api/logout",
      method: "POST",
    },
    gameTitle: {
      url: "/api/gameTitle",
      method: "POST",
      data: {
        gameID: null,
      },
    },
    lastSettleInfo: {
      url: "/api/lastSettleInfo",
      method: "POST",
      data: {
        gameID: null,
      },
    },
    betInfo: {
      url: "/api/betInfo",
      method: "POST",
      data: {
        gameID: null,
        tabID: null,
      },
    },
    winExample: {
      url: "/api/winExample",
      method: "POST",
      data: {
        gameID: null,
        tabID: null,
      },
    },
    maxWin: {
      url: "/api/maxWin",
      method: "POST",
      data: {
        gameID: null,
        tabID: null,
      },
    },
    betList: {
      url: "/api/betList",
      method: "POST",
      data: {
        gameID: null,
        tabID: null,
      },
    },
    betHistory: {
      url: "/api/betHistory",
      method: "POST",
      data: {
        gameID: null,
        tabID: null,
        items: 10,
        page: 1,
      },
    },
    map: {
      url: "/api/map",
      method: "POST",
      data: {
        gameID: null,
        tabID: null,
        sort: 1,
      },
    },
    settleHistory: {
      url: "/api/settleHistory",
      method: "POST",
      data: {
        gameID: null,
        subID: null,
      },
    },
  },
};
