import { combineReducers } from "redux";
import siteconfigReducer from "./siteconfig";
import navigationsReducer from "./navigations";
import userReducer from "./user";
import walletsReducer from "./wallets";
import announcementsReducer from "./announcements";

export default combineReducers({
  siteconfig: siteconfigReducer,
  navigations: navigationsReducer,
  user: userReducer,
  wallets: walletsReducer,
  announcements: announcementsReducer,
});
