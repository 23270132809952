import {
  SET_IS_ANNOUNCEMENTS_LOADING,
  SET_ANNOUNCEMENTS,
} from "@REDUX/actionTypes/announcements";

const getInitialState = () => ({
  isAnnouncementsLoading: false,
  announcements: [
    {
      id: 1,
      content:
          "1.我們擁有各行各業的經驗！",
    },
    {
      id: 2,
      content:
          "2.可以以最快的速度和最合理的價格構建自己的應用程式！",
    },
    {
      id: 3,
      content:
          "3.讓您的公司擁有全面的全網管理系統，包括PC，移動和通信軟體！",
    },
    {
      id: 4,
      content:
          "4.裕智資訊擁有經驗豐富的資深工程師，無論在製作行動裝置 app 或公司管理系統方面，皆能提供面面俱到的服務！",
    },
    {
      id: 5,
      content:
          "5.裕智資訊主要的工作在提供 SMEs 方便的操作系統，並利用行動裝置整合外部與內部的工作流程，讓員工與老闆在任何地方任何時間皆能使用並且查詢報表。！",
    },
  ],
});

export default function announcementsReducer(
  state = getInitialState(),
  action
) {
  switch (action.type) {
    case SET_IS_ANNOUNCEMENTS_LOADING: {
      return {
        ...state,
        isAnnouncementsLoading: action.payload,
      };
    }
    case SET_ANNOUNCEMENTS: {
      return {
        ...state,
        announcements: action.payload,
      };
    }
    default:
      return state;
  }
}
