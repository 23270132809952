import axios from "axios";
import { getApiDetail } from "@AXIOS/apisDetails";
import store from "@REDUX/store";
import { openModal } from "@DEVICE_TYPE/utils/modal";

const requesterConfig = {
  baseURL: "http://localhost:9527",
  timeout: 5000,
};

const requester = axios.create(requesterConfig);

requester.interceptors.request.use(
  (config) => {
    if (/^(\/user|\/games)/.test(config.url)) {
      config.baseURL = "http://c31e91decab4.ngrok.io";
    } else {
      config.baseURL = "http://localhost:9527";
    }
    if (store.getState().user.token) {
      config.headers = {
        ...config.headers,
        Authorization: store.getState().user.token || "",
      };
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

requester.interceptors.response.use(
  (response) => {
    let isError = false;

    let title = "提示";
    let modalType = "warning";
    let content = "";

    if (response.status > 400) {
    } else if (response.data) {
      if (response.data.isError !== void 0) {
        isError = response.data.isError;
        content = response.data.msg;
      } else if (response.data.code !== void 0) {
        isError = !!response.data.code;
        if (response.data.message) {
          content = `[${response.data.code}]: ${response.data.message}`;
        }
      }
      if (content) {
        openModal(modalType, {
          title,
          content,
        });
      }
    }
    return {
      isError,
      ...response,
    };
  },
  (error) => {
    return Promise.reject(error);
  }
);

const requsetHandler = ({ method, apiName, data = {}, params = {} }) => {
  const apiDetail = getApiDetail({
    method,
    apiName,
  });

  if (apiDetail.method === "GET") {
    Object.keys(params).forEach((key) => {
      apiDetail.url = apiDetail.url.replace(
        new RegExp(`{${key}}`),
        params[key]
      );
    });
  } else if (method === "POST") {
    const defaultData = apiDetail.data ? apiDetail.data : {};
    apiDetail.data = {
      ...defaultData,
      ...data,
    };
  }

  return apiDetail;
};

const responseHandler = async (response) => {
  return response.data;
};

async function httpRequset(request) {
  const newRequest = requsetHandler(request);
  const response = await requester(newRequest);
  return responseHandler(response);
}

export { httpRequset };
