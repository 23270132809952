import store from "@REDUX/store";
import {
  setIsSiteconfigLoading,
  setSiteconfig,
} from "@REDUX/actionCreators/siteconfig";
import { httpGetSiteconfig } from "@AXIOS/get";

export const getSiteconfig = async () => {
  store.dispatch(setIsSiteconfigLoading(true));
  // const response = await httpGetSiteconfig();
  var response =
      {
        isError:false,
        msg:"",
        data:{}
      };
  const { isError, data } = response;
  if (!isError) {
    store.dispatch(setSiteconfig(data));
  }
  store.dispatch(setIsSiteconfigLoading(false));

  return response;
};
