import "./Loading.less";
import { Row, Col, Spin } from "antd";

function Loading() {
  return (
    <Row className="window-loading" justify="center" align="middle">
      <Col>
        <Spin tip="loading..." size="large" style={{ color: "white" }} />
      </Col>
    </Row>
  );
}

export default Loading;
