import store from "@REDUX/store";
import { setIsWalletsLoading, setWallets } from "@REDUX/actionCreators/wallets";
import { httpGetWallets } from "@AXIOS/get";

export const getWallets = async () => {
  store.dispatch(setIsWalletsLoading(true));
  const response = await httpGetWallets();
  const { isError, data } = response;
  if (!isError) {
    store.dispatch(setWallets(data));
  }
  store.dispatch(setIsWalletsLoading(false));
  return response;
};
