import {
  SET_IS_SITECONFIG_LOADING,
  SET_SITECONFIG,
} from "@REDUX/actionTypes/siteconfig";

export const setIsSiteconfigLoading = (payload) => ({
  type: SET_IS_SITECONFIG_LOADING,
  payload,
});

export const setSiteconfig = (payload) => ({
  type: SET_SITECONFIG,
  payload,
});
