import { getCookie, setCookie, resetCookie } from "@STORAGE/cookies";
import { SET_USER_INFO, RESET_USER_INFO } from "@REDUX/actionTypes/user";

const getInitialState = () => ({
  token: getCookie("token"),
  account: getCookie("account"),
});

export default function userReducer(state = getInitialState(), action) {
  switch (action.type) {
    case SET_USER_INFO: {
      const userInfo = action.payload;
      setCookie("token", userInfo.token);
      setCookie("account", userInfo.account);
      return userInfo;
    }
    case RESET_USER_INFO: {
      resetCookie("token");
      resetCookie("account");
      return getInitialState();
    }
    default:
      return state;
  }
}
