import "@PC/styles/index.less";
import { lazy, Suspense } from "react";
import Loading from "@DEVICE_TYPE/widgets/Loading/Loading";

const Layout = lazy(() =>
  import("@PC_LAYOUT" /* webpackChunkName: "PC_LAYOUT" */)
);

function StyledPcLayout({ children }) {
  return (
    <Suspense fallback={<Loading />}>
      <Layout>{children}</Layout>
    </Suspense>
  );
}

export default StyledPcLayout;
