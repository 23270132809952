import {
  SET_IS_WALLETS_LOADING,
  SET_WALLETS,
} from "@REDUX/actionTypes/wallets";

export const setIsWalletsLoading = (payload) => ({
  type: SET_IS_WALLETS_LOADING,
  payload,
});

export const setWallets = (payload) => ({
  type: SET_WALLETS,
  payload,
});
