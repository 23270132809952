import {
  SET_IS_NAVIGATIONS_LOADING,
  SET_NAVIGATIONS,
} from "@REDUX/actionTypes/navigations";

const getInitialState = () => ({
  isNavigationsLoading: false,
  navigations: [
    {
      title: "Home",
      path: "/",
    },
    {
      title: "About",
      path: "/about",
    },
    {
      title: "Service",
      path: "/service",
    },
    {
      title: "HTML5 Games",
      path: "/html5Games",
    },
    // {
    //   title: "Industry",
    //   path: "/liveLobby",
    //   subGroup: [
    //     {
    //       text: "Technology",
    //       path: "/liveLobby/ag",
    //     },
    //     {
    //       text: "Manufacturing",
    //       path: "/liveLobby/bg",
    //     },
    //   ],
    // },
  ],
});

export default function navigationsReducer(state = getInitialState(), action) {
  switch (action.type) {
    case SET_IS_NAVIGATIONS_LOADING: {
      return {
        ...state,
        isNavigationsLoading: action.payload,
      };
    }
    case SET_NAVIGATIONS: {
      return {
        ...state,
        navigations: action.payload,
      };
    }
    default:
      return state;
  }
}
