import { checkIsMobile } from "@UTILS/checkers";
import Layout from "@DEVICE_TYPE/layouts";
import { routes, routesObj } from "@DEVICE_TYPE/router";

function useApp() {
  const isMobile = checkIsMobile();
  return {
    isMobile,
    MatchLayout: Layout,
    matchRoutes: routes,
    matchRoutesObj: routesObj,
  };
}

export default useApp;
