import { lazy } from "react";

const routes = [
  {
    path: "/",
    name: "Home",
    isPrivate: false,
    needLayout: true,
    needCache: true,
    component: lazy(() => import("@PC_HOME" /* webpackChunkName: "PC_HOME" */)),
  },
  // {
  //   path: "/login",
  //   name: "Login",
  //   exact: true,
  //   isPrivate: false,
  //   component: lazy(() => import("@PC_LOGIN" /* webpackChunkName: "PC_LOGIN" */)),
  // },
  {
    path: "/contact",
    name: "contact",
    isPrivate: false,
    needLayout: true,
    needCache: true,
    component: lazy(() =>
      import("@PC_CONTACT" /* webpackChunkName: "PC_CONTACT" */)
    ),
  },
  // {
  //   path: "/promotion",
  //   name: "Promotion",
  //   isPrivate: false,
  //   needLayout: true,
  //   needCache: true,
  //   component: lazy(() =>
  //     import("@PC_PROMOTION" /* webpackChunkName: "PC_PROMOTION" */)
  //   ),
  // },
  // {
  //   path: "/usercenter",
  //   name: "UserCenter",
  //   isPrivate: false,
  //   needLayout: true,
  //   needCache: true,
  //   component: lazy(() =>
  //     import("@PC_USERCENTER" /* webpackChunkName: "PC_USERCENTER" */)
  //   ),
  // },
  // {
  //   path: "/helpcenter",
  //   name: "HelpCenter",
  //   isPrivate: false,
  //   needLayout: true,
  //   needCache: true,
  //   component: lazy(() =>
  //     import("@PC_HELPCENTER" /* webpackChunkName: "PC_HELPCENTER" */)
  //   ),
  // },
  // {
  //   path: "/agentcenter",
  //   name: "AgentCenter",
  //   isPrivate: false,
  //   needLayout: true,
  //   needCache: true,
  //   component: lazy(() =>
  //     import("@PC_AGENTCENTER" /* webpackChunkName: "PC_AGENTCENTER" */)
  //   ),
  // },
  {
    path: "/about",
    name: "About",
    isPrivate: false,
    needLayout: true,
    needCache: true,
    component: lazy(() =>
      import("@PC_ABOUTUS")
    ),
  },
  {
    path: "/service",
    name: "Service",
    isPrivate: false,
    needLayout: true,
    needCache: true,
    component: lazy(() =>
      import("@PC_SERVICE" /* webpackChunkName: "PC_SERVICE" */)
    ),
  },
  {
    path: "/html5Games",
    name: "HTML5Games",
    isPrivate: false,
    needLayout: true,
    needCache: true,
    component: lazy(() =>
      import("@PC_HTML5GAMES" /* webpackChunkName: "PC_GAMES" */)
    ),
  },
  // {
  //   path: "/lotteryLobby",
  //   name: "LotteryLobby",
  //   isPrivate: false,
  //   needLayout: true,
  //   needCache: true,
  //   component: lazy(() =>
  //     import("@PC_LOTTERYLOBBY" /* webpackChunkName: "PC_LOTTERYLOBBY" */)
  //   ),
  // },
  // {
  //   path: "/sportLobby",
  //   name: "SportLobby",
  //   isPrivate: false,
  //   needLayout: true,
  //   needCache: true,
  //   component: lazy(() =>
  //     import("@PC_SPORTLOBBY" /* webpackChunkName: "PC_SPORTLOBBY" */)
  //   ),
  // },
  // {
  //   path: "/esportLobby",
  //   name: "EsportLobby",
  //   isPrivate: false,
  //   needLayout: true,
  //   needCache: true,
  //   component: lazy(() =>
  //     import("@PC_ESPORTLOBBY" /* webpackChunkName: "PC_ESPORTLOBBY" */)
  //   ),
  // },
  {
    path: "/notfound",
    name: "NotFound",
    isPrivate: false,
    needLayout: false,
    component: lazy(() =>
      import("@PC_NOTFOUND" /* webpackChunkName: "PC_NOTFOUND" */)
    ),
  },
];

const routesObj = routes.reduce((acc, route) => {
  acc[route.path] = route;
  return acc;
}, {});

export { routes, routesObj };
