import { Modal } from "antd";

export const openModal = (type, config) => {
  Modal[type]({
    getContainer: "#modalMount",
    maskStyle: {
      position: "absolute",
    },
    destroyOnClose: true,
    visible: true,
    mask: true,
    centered: true,
    closable: true,
    maskClosable: true,
    width: 400,
    title: "提示",
    ...config,
  });
};

export const closeModal = () => {
  Modal.destroyAll();
};
