import {
  SET_IS_WALLETS_LOADING,
  SET_WALLETS,
} from "@REDUX/actionTypes/wallets";

const getInitialState = () => ({
  isWalletsLoading: false,
  wallets: {
    main: 0,
  },
});

export default function userReducer(state = getInitialState(), action) {
  switch (action.type) {
    case SET_IS_WALLETS_LOADING: {
      return {
        ...state,
        isWalletsLoading: action.payload,
      };
    }
    case SET_WALLETS: {
      return {
        ...state,
        wallets: action.payload,
      };
    }
    default:
      return state;
  }
}
