import "./App.less";
import { Suspense, useEffect } from "react";
import { useSelector } from "react-redux";
import { HashRouter as Router, Route } from "react-router-dom";
import CacheRoute, { CacheSwitch } from "react-router-cache-route";
import { getSiteconfig } from "@REDUX/actions/siteconfig";
import { getWallets } from "@REDUX/actions/wallets";
import Loading from "@DEVICE_TYPE/widgets/Loading/Loading";
import useApp from "@HOOKS/useApp";

function CacheRoutes({ routes }) {
  const { MatchLayout } = useApp();
  return routes.map(({ path, needLayout, needCache, component: Component }) => {
    const MatchRoute = needCache ? CacheRoute : Route;
    return (
      <MatchRoute
        key={path}
        exact={true}
        path={path}
        when="always"
        render={() => {
          if (needLayout) {
            return (
              <MatchLayout>
                <Component />
              </MatchLayout>
            );
          } else {
            return (
              <Suspense fallback={<Loading />}>
                <Component />
              </Suspense>
            );
          }
        }}
      />
    );
  });
}

function App() {
  const { isMobile, matchRoutes } = useApp();
  const token = useSelector((state) => state.user.token);
  const siteconfig = useSelector((state) => state.siteconfig.siteconfig);
  const isSiteConfigLoading = useSelector(
    (state) => state.siteconfig.isSiteconfigLoading
  );

  useEffect(() => {
    getSiteconfig();
  }, []);

  useEffect(() => {
    if (token) {
      getWallets();
    }
  }, [token]);

  // useEffect(() => {
  //   if (!isMobile && siteconfig.livechatKey) {
  //     const script_tag = document.createElement("script");
  //     script_tag.type = "text/javascript";
  //     script_tag.text = `
	// 			window.__lc = window.__lc || {};
	// 			window.__lc.license = ${siteconfig.livechatKey};
	// 			;(function(n,t,c){function i(n){return e._h?e._h.apply(null,n):e._q.push(n)}var e={_q:[],_h:null,_v:"2.0",on:function(){i(["on",c.call(arguments)])},once:function(){i(["once",c.call(arguments)])},off:function(){i(["off",c.call(arguments)])},get:function(){if(!e._h)throw new Error("[LiveChatWidget] You can't use getters before load.");return i(["get",c.call(arguments)])},call:function(){i(["call",c.call(arguments)])},init:function(){var n=t.createElement("script");n.async=!0,n.type="text/javascript",n.src="https://cdn.livechatinc.com/tracking.js",t.head.appendChild(n)}};!n.__lc.asyncInit&&e.init(),n.LiveChatWidget=n.LiveChatWidget||e}(window,document,[].slice))
	// 		`;
  //     document.body.appendChild(script_tag);
  //   }
  // }, [isMobile, siteconfig]);

  return (
    <Router>
      <div className="app-container">
        {isSiteConfigLoading ? (
          <Loading />
        ) : (
          <CacheSwitch>
            <CacheRoutes routes={matchRoutes} />
          </CacheSwitch>
        )}
      </div>
    </Router>
  );
}

export default App;
