import { httpRequset } from "./index.js";

export const httpGetSiteconfig = async () => {
  return await httpRequset({
    method: "GET",
    apiName: "siteconfig",
  });
};

export const httpGetWallets = async () => {
  return await httpRequset({
    method: "GET",
    apiName: "wallets",
  });
};

export const httpGetNavigations = async () => {
  return await httpRequset({
    method: "GET",
    apiName: "navigations",
  });
};

export const httpGetAnnouncements = async () => {
  return await httpRequset({
    method: "GET",
    apiName: "announcements",
  });
};

export const httpGetGameList = async () => {
  return await httpRequset({
    method: "GET",
    apiName: "gameList",
  });
};

export const httpGetTabList = async ({ params }) => {
  return await httpRequset({
    method: "GET",
    apiName: "tabList",
    params,
  });
};

export const httpGetWinInfo = async () => {
  return await httpRequset({
    method: "GET",
    apiName: "winInfo",
  });
};

export const httpGetPromotion = async () => {
  return await httpRequset({
    method: "GET",
    apiName: "promotion",
  });
};

export const httpGetPromotionDetail = async ({ restful }) => {
  return await httpRequset({
    method: "GET",
    apiName: "promotionDetail",
    restful,
  });
};

export const httpGetBetHistory = async ({ params }) => {
  return await httpRequset({
    method: "GET",
    apiName: "betHistory",
    params,
  });
};

export const httpGetBetList = async ({ restful }) => {
  return await httpRequset({
    method: "GET",
    apiName: "betList",
    restful,
  });
};

export const httpGetGameTitle = async ({ restful }) => {
  return await httpRequset({
    method: "GET",
    apiName: "gameTitle",
    restful,
  });
};

export const httpGetLastSettleInfo = async ({ restful, params }) => {
  return await httpRequset({
    method: "GET",
    apiName: "lastSettleInfo",
    restful,
    params,
  });
};

export const httpGetUserInfo = async () => {
  return await httpRequset({
    method: "GET",
    apiName: "userInfo",
  });
};