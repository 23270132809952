import Cookies from "js-cookie";

const setCookie = (key, value) => {
  return Cookies.set(key, value, { expires: 7 });
};

const getCookie = (key) => {
  return Cookies.get(key);
};

const resetCookie = (key) => {
  return Cookies.remove(key);
};

export { setCookie, getCookie, resetCookie };
