import {
  SET_IS_SITECONFIG_LOADING,
  SET_SITECONFIG,
} from "@REDUX/actionTypes/siteconfig";

const getInitialState = () => ({
  isSiteconfigLoading: true,
  siteconfig: {
    livechatKey: "",
  },
});

export default function siteconfigReducer(state = getInitialState(), action) {
  switch (action.type) {
    case SET_IS_SITECONFIG_LOADING: {
      return {
        ...state,
        isSiteconfigLoading: action.payload,
      };
    }
    case SET_SITECONFIG: {
      return {
        ...state,
        siteconfig: action.payload,
      };
    }
    default:
      return state;
  }
}
